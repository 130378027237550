<template>
  <div>
    404 page
  </div>
</template>

<script>
export default {
  name: 'error_404'
}
</script>

<style scoped>

</style>
